


































import useAccessory from "@/use/accessory";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
    available: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root, emit }) {
    const { getAccessoryIcon, getAccessoryName } = useAccessory();

    const state = reactive({
      search: "",
      loading: false,
      items: [],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      headers: [
        { value: "select", text: "", width: 1, sortable: false },
        { value: "name", text: "Nazwa", sortable: false },
        { value: "type", text: "Typ", sortable: false },
        {
          value: "serial",
          text: "Numer seryjny/identyfikacyjny",
          sortable: false,
        },
      ],
    });

    const model = computed({
      get: () => props.value,
      set: (value) => emit("input", value),
    });

    const fetch = () => {
      state.loading = true;

      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(
          props.userId
            ? `inventory/user/${props.userId}/accessory`
            : "inventory/accessory",
          {
            params: {
              type: props.available ? "available" : "occupied",
              search: state.search || undefined,
              page: state.options?.page || undefined,
              itemsPerPage: state.options?.itemsPerPage || undefined,
            },
          }
        )
        .then(({ data: { accessories, total } }) => {
          state.items = accessories;
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced(
      [
        () => props.userId,
        () => props.available,
        () => state.search,
        () => state.options,
      ],
      fetch,
      { deep: true, immediate: true, debounce: 500, maxWait: 5000 }
    );

    return { state, model, getAccessoryIcon, getAccessoryName };
  },
});
